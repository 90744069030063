<template>
  <div>
    <div
      :style="
        'background-image: url(' +
        require('@/assets/images/spriplan/background.jpg') +
        ');'
      "
      class="rounded-t bg-cover bg-center h-48"
    ></div>
    <div class="bg-blue-700 text-gray-200 p-4 py-6">
      <div class="uppercase text-xs font-bold">Willkommen bei</div>
      <h1 class="text-xl">
        SpriPlan - Ingenieurbüro für stationären Brandschutz
      </h1>
    </div>
    <div class="p-4">
      <div>
        <div class="flex items-stretch my-8">
          <div class="w-1/6 flex-none flex justify-center">
            <img
              src="@/assets/images/spriplan/concept.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            Erstellung von Löschanlagenkonzepten als Basis einer aussagefähigen
            Planung
          </div>
        </div>
        <div class="flex items-stretch justify-center my-8">
          <div class="justify-center w-1/6 flex-none">
            <img
              src="@/assets/images/spriplan/floor-plan.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            Planung von stationären Löschanlagen durch alle Leistungsphasen der
            HOAI nach den Richtlinien des VdS, FM oder NFPA
          </div>
        </div>
        <div class="flex items-stretch justify-center my-8">
          <div class="justify-center w-1/6 flex-none">
            <img
              src="@/assets/images/spriplan/worker.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            <div class="flex justify-middle items-stretch text-left">
              VdS-Fachmann für Sprinkler- und Gaslöschanlagen
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto">
        <h2 class="text-lg my-4 text-center">Kontakt</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 my-2">
          <div class="leading-6.75">
            <p>
              SpriPlan GmbH &amp; Co. KG<br />
              Erhard-Stangl-Ring 14<br />
              84435 Lengdorf
            </p>
            <p class="my-2 text-sm">
              Eingetragen unter HR A 104854 am AG München<br />
              USt.-ID: DE304946561
            </p>
            <p class="my-2 text-sm">
              Haftende Gesellschaft:<br />SpriPlan Verwaltungs-GmbH in
              Lengdorf<br />
              Geschäftsführer: Markus Hösl<br />
              Eingetragen unter HR B 222961 am AG München
            </p>
          </div>
          <div>
            <table>
              <tr>
                <td class="px-2 pl-0">Telefon:</td>
                <td class="px-2">+49 8083 908598</td>
              </tr>
              <tr>
                <td class="px-2 pl-0">Telefax:</td>
                <td class="px-2">+49 8083 908597</td>
              </tr>
              <tr>
                <td class="px-2 pl-0">E-Mail:</td>
                <td class="px-2">
                  <a href="mailto:info@spriplan.de">info@spriplan.de</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
